import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Group, Text, Title, Card } from "@mantine/core";

export const FileScreen = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} title="">
        {/* Modal content */}
      </Modal>
      <Card withBorder style={{margin: 10}}>
        <Title order={3}>Files</Title>
      </Card>
    </>
  );
};
