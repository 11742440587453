import { Accordion } from "@mantine/core";

export const ToggleBox = (
  {title,
  open,
  content,
  style,
  variant,
  chevronPosition}
) => {
  return (
    <Accordion
      style={
        style
          ? style
          : { padding: 10, paddingTop: 10, flex: 600, minWidth: 350 }
      }
      variant={variant ? variant : "contained"}
      radius="xs"
      chevronPosition={chevronPosition ? chevronPosition : "left"}
      defaultValue={open ? "main" : null}
    >
      <Accordion.Item value={"main"}>
        <Accordion.Control>{title}</Accordion.Control>
        <Accordion.Panel>{content}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
