import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  TextInput,
  Textarea,
  useMantineTheme,
  SegmentedControl,
  Dialog,
  Overlay,
  Grid,
} from "@mantine/core";
import {
  getLastLeads,
  getLeadClientList,
  submitLead,
} from "../../functions/api";
import { Knob } from "primereact/knob";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Mail, MessageCircle, Phone } from "tabler-icons-react";

const LeadScreen = () => {
  const intl = useIntl();
  const [form, setForm] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    comment: "",
    deadline: null,
    mode: "jbmnj",
    loader: 100,
  });
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const [showDialog, setShowDialog] = useState(false);
  const [countdown, setCountdown] = useState(6);

  const theme = useMantineTheme();
  const [working, setWorking] = useState(false);
  const [last, setLast] = useState([]);

  const [modeList, setModeList] = useState();

  useEffect(() => {
    getLeadClientList().then((x) => {
      setModeList(x);
      setForm({ ...form, mode: x[0].lrid });
    });
  }, []);

  useEffect(() => {
    getLastLeads().then((x) => {
      setLast(x);
    });
  }, [success, errors]);

  useEffect(() => {
    let countdownInterval;

    if (showDialog) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(countdownInterval);
      handleCountdownComplete();
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [showDialog, countdown]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // validate email field
    if (name === "email") {
      const isValidEmail = /\S+@\S+\.\S+/.test(value);
      setErrors({ ...errors, email: !isValidEmail });
    } else {
      setErrors({ ...errors, [name]: false });
    }

    setForm({ ...form, [name]: value });
  };

  const handleDeadlineChange = (value) => {
    setForm({ ...form, deadline: value });
  };

  const handleModeChange = (value) => {
    setForm({
      ...form,
      mode: value,
      old: modeList.find((item) => item.value === value)?.redunant_mode,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (form.name == null || form.name === "") {
      window.alert(intl.formatMessage({ id: "must_enter_email_phone" }));
      return false;
    }

    if (
      (form.phoneNumber == null || form.phoneNumber === "") &&
      (form.email == null || form.email === "")
    ) {
      if (
        !window.confirm(
          intl.formatMessage({ id: "confirm_submit_no_deadline" })
        )
      ) {
        return false;
      }
    }

    if (form.comment == null || form.comment === "") {
      if (
        !window.confirm(intl.formatMessage({ id: "confirm_submit_no_descr" }))
      ) {
        return false;
      }
    }

    // check that at least one of email or phone number fields is not empty
    if (!form.email && !form.phoneNumber) {
      setErrors({ ...errors, email: true, phoneNumber: true });
      return false;
    }

    // check email field for validity
    if (form.email && errors.email) {
      return false;
    }

    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setCountdown(5); // Reset countdown to initial value
  };

  const handleCountdownComplete = async () => {
    setShowDialog(false);
    setSubmitting(true);

    try {
      if (working == false) {
        setWorking(true);
        const response = await submitLead(form);
        if (response) {
          setSuccess(true);
          setForm({
            name: "",
            surname: "",
            phoneNumber: "",
            email: "",
            comment: "",
            deadline: null,
            mode: modeList[0].lrid,
            loader: 100,
          });
          setCountdown(5);
          setWorking(false);
        }
      }
    } catch (error) {
      alert(error);
    }

    setSubmitting(false);
  };

  // const modeList = [
  //   { label: "Maksātnespēja", value: "jbmnj", color: "#32CD32" },
  //   { label: "Mežu resursu fonds", value: "lmrf", color: "#0e992a" },
  //   { label: "CRM - SOMECRM", value: "crm", color: "#4B0082" },
  //   // { label: "Apdrošināšana", value: "insurance" },
  //   // { label: "Matrači", value: "erti", color: "#4B0082" },
  //   { label: "Saules paneļi", value: "sungo", color: "#DAA520" },
  //   { label: "Auto Rīga", value: "auto", color: "#FF0000" },
  //   // {
  //   //   label: "Rekuperācija/ventilācija",
  //   //   value: "majaelpo",
  //   //   color: "rgb(55, 108, 202)",
  //   // },
  // ];

  return (
    <div>
      {showDialog && (
        <Overlay
          // color={
          //   modeList && modeList.find((item) => item.value === form.mode)?.color
          // }
          opacity={0.3}
        />
      )}
      <Card shadow="sm" padding="lg">
        <div style={{ overflow: "auto" }}>
          {modeList && (
            <SegmentedControl
              style={{ marginTop: 10, marginBottom: 10, width: "100%" }}
              value={form.mode}
              onChange={handleModeChange}
              data={modeList}
            />
          )}
        </div>

        {showDialog && (
          <Dialog
            style={{ display: "flex", justifyContent: "center" }}
            opened={showDialog}
            onClose={handleDialogClose}
            overlayOpacity={0.6}
            title={intl.formatMessage({ id: "countdown" })}
            position={{ top: "45%", left: "45%" }}
          >
            <div style={{ textAlign: "center" }}>
              <Knob
                style={{ display: "flex", justifyContent: "center" }}
                value={countdown}
                readOnly
                size={200}
                min={0}
                max={5}
                showValue={false}
                valueColor={
                  modeList &&
                  modeList.find((item) => item.value === form.mode)?.color
                }
              />
              <div>
                <FormattedMessage id="being_sent_to" />:{" "}
                <span style={{ fontWeight: "800" }}>
                  {" "}
                  {modeList &&
                    modeList.find((item) => item.value === form.mode)?.label}
                </span>
              </div>
              <p>
                <FormattedMessage id="submitting_in" />: {countdown}{" "}
                <FormattedMessage id="seconds" />
              </p>
              <Button
                style={{ color: "white", backgroundColor: "dodgerblue" }}
                onClick={handleDialogClose}
              >
                <FormattedMessage id="cancel" />
              </Button>
            </div>
          </Dialog>
        )}
        {success ? (
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: theme.colors.green[6] }}>
              <FormattedMessage id="form_submitted" />
            </h3>
            <Button
              style={{ backgroundColor: "lightgreen" }}
              variant="outline"
              onClick={() => setSuccess(false)}
            >
              <FormattedMessage id="submit_another" />
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextInput
              label={intl.formatMessage({ id: "name" })}
              placeholder={intl.formatMessage({ id: "name" })}
              name="name"
              value={form.name}
              onChange={handleChange}
            />
            <TextInput
              label={intl.formatMessage({ id: "surname" })}
              placeholder={intl.formatMessage({ id: "surname_ph" })}
              name="surname"
              value={form.surname}
              onChange={handleChange}
            />
            <TextInput
              label={intl.formatMessage({ id: "phone_nr" })}
              placeholder={intl.formatMessage({ id: "phone_nr_enter_ph" })}
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handleChange}
            />
            <TextInput
              label={intl.formatMessage({ id: "email" })}
              placeholder={intl.formatMessage({ id: "email_enter_ph" })}
              name="email"
              value={form.email}
              onChange={handleChange}
              error={errors.email}
              errormessage={intl.formatMessage({ id: "enter_valid_email" })}
            />
            <Textarea
              label={intl.formatMessage({ id: "comment" })}
              placeholder={intl.formatMessage({ id: "comment_enter_ph" })}
              name="comment"
              value={form.comment}
              onChange={handleChange}
            />
            {/* {form.mode == "jbmnj" && (
              <div style={{ marginTop: "1rem" }}>
                <DateTimePicker
                  label="Deadline"
                  placeholder="Enter your deadline"
                  name="deadline"
                  value={form.deadline}
                  onChange={handleDeadlineChange}
                  minDate={new Date()}
                  inputProps={{ autoComplete: "off" }}
                />
              </div>
            )} */}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{ backgroundColor: "dodgerblue", zIndex: 5 }}
                type="submit"
                disabled={submitting}
              >
                {submitting ? (
                  <FormattedMessage id="submitting" />
                ) : (
                  <FormattedMessage id="submit" />
                )}
              </Button>
              <span
                style={{
                  fontSize: 40,
                  fontWeight: 800,
                  position: "absolute",
                  right: 0,
                  zIndex: 2,
                  bottom: -12,
                  color:
                    modeList &&
                    modeList.find((item) => item.value === form.mode)?.color,
                  opacity: 0.2,
                }}
              >
                {modeList &&
                  modeList.find((item) => item.value === form.mode)?.label}
              </span>
            </div>
          </form>
        )}
      </Card>
      <div>
        {last && (
          <Card withBorder mt={10}>
            <h3 style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>
              Tavi pēdējie 10 pievienotie
              <br />
              <small style={{ color: "grey" }}>
                Nesakritību gadījumā ziņot
              </small>
            </h3>
            {last &&
              last.map((d) => {
                return <LeadListItem d={d} modeList={modeList} />;
              })}
          </Card>
        )}
      </div>
    </div>
  );
};

export const LeadListItem = ({ d, modeList }) => {
  return (
    <Grid
      style={{
        borderBottom: "solid 1px lightgray",
        justifyContent: "space-between",
      }}
    >
      <Grid.Col xs={5}>
        <div style={{ fontWeight: "600" }}>
          {d?.name} {d?.surname}
        </div>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {d.phone && <Phone size={14} />} {d?.phone}
          {d.email && <Mail size={14} style={{ marginLeft: 20 }} />} {d?.email}{" "}
        </span>
      </Grid.Col>
      <Grid.Col xs={5} style={{ display: "block" }}>
        {d.added_by ? (
          <span style={{ color: "grey", fontSize: 14 }}>
            <FormattedMessage id="ar_created_by" />
            {d?.u_name} {d?.u_surname}
          </span>
        ) : (
          ""
        )}
        {d.comment && (
          <span style={{ display: "flex" }}>
            <MessageCircle style={{ marginRight: 5 }} size={14} />
            {d?.comment}
          </span>
        )}
      </Grid.Col>
      <Grid.Col xs={2}>
        <span
          style={{
            fontSize: 14,
            color: "grey",
            textAlign: "right",
          }}
        >
          <div>{d.created?.substring(0, 10)}</div>
          <div>
            {modeList.find((item) => item.value === d.system)?.label ||
              d?.system}
          </div>
        </span>
      </Grid.Col>
    </Grid>
  );
};
export default LeadScreen;
