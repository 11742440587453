export const env = { ...process.env, ...window['env'] }
// export const env = {
//   BACKEND_URL: "http://localhost:3011/",
//   LOGO: "jm_logo.png",
//   DEFAULT_SERVICE:
//     "Maksātnespējas līguma sagatavošana ar garantiju par lietas ierosināšanu",
//   DEFAULT_PRICE: 53.71,
//   LEADS: false,
//   LIMITED_TASKS: false,
//   events: true,
// };
