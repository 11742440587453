import { useEffect, useState } from "react";
import {
  adminGetLeadClient,
  adminGetLeadClientList,
  adminSaveLeadClient,
  adminVerifyLeadClientApi,
} from "../../functions/api";
import {
  Button,
  Card,
  ColorSwatch,
  Box,
  LoadingOverlay,
  Grid,
  TextInput,
  ColorInput,
  Select,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Pencil } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";

export const AdminLeadClients = () => {
  const [clients, setClients] = useState();

  useEffect(() => {
    adminGetLeadClientList().then((x) => setClients(x));
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        style={{ marginTop: 10, backgroundColor: "dodgerblue", color: "white" }}
        color="primary"
        onClick={() => (window.location.href = window.location.href + "/new")}
      >
        {" "}
        <FormattedMessage id="add" />
      </Button>
      {clients &&
        clients?.map((client) => (
          <Card withBorder mt={5}>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              key={client.lrid}
            >
              <div>
                {client.index ? client.index + ". " : null} {client.name}
                <br />
                <div style={{ fontSize: 10 }}>
                  {client?.url?.length > 50
                    ? client?.url.slice(0, 50) + "..."
                    : client?.url}{" "}
                  <br />{" "}
                  {client?.key?.length > 50
                    ? client.key?.slice(0, 50) + "..."
                    : client.key}
                </div>
              </div>
              <div></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <ColorSwatch color={client.color}></ColorSwatch>
                </div>
                {/* <a
                  href={`#`}
                  style={{ marginRight: 10, padding: 3 }}
                  title="Empty"
                >
                  <Trash color="red" />
                </a> */}
                <a
                  style={{ padding: 3 }}
                  href={`${window.location.href}/${client.lrid}`}
                >
                  <Pencil />
                </a>
              </div>
            </div>
          </Card>
        ))}
    </div>
  );
};

export const AdminManageLeadClient = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState();

  const navigate = useNavigate();
  const { key } = useParams();

  const verifyApiData = () => {
    if (!/\/api\/v0\//.test(values?.url)) {
      window.alert(intl.formatMessage({ id: "somecrm_only" }));
    } else {
      adminVerifyLeadClientApi({
        url: values?.url?.replace("/api/v0/", "/api/v0/verify/"),
        token: values.key,
      }).then((x) => {
        if (x.win) {
          window.alert(intl.formatMessage({ id: "api_correct" }));
        } else {
          window.alert(intl.formatMessage({ id: "api_incorrect" }));
        }
      });
    }
  };

  useEffect(() => {
    if (key != "new") {
      adminGetLeadClient(key).then((x) => {
        setValues(x[0]);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveLeadClient = async () => {
    adminSaveLeadClient(values).then((x) => {
      if (key != "new") {
        window.location.reload();
      } else {
        if (x.win) {
          window.location.href = "/admin/lead-clients/" + x.win;
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <>
          <Box style={{ height: 700, width: "100%" }} pos="relative">
            <LoadingOverlay visible={loading} overlayBlur={2} />
          </Box>
        </>
      ) : (
        <div>
          <Card withBorder style={{ margin: 10 }}>
            <div
              style={{
                fontSize: 24,
                fontWeight: 800,
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormattedMessage id="lead_client_management" />
              <Button
                variant="contained"
                style={{
                  marginTop: 10,
                  backgroundColor: "dodgerblue",
                  color: "white",
                }}
                color="primary"
                onClick={() => (window.location.href = "/admin/lead-clients")}
              >
                <FormattedMessage id="return" />
              </Button>
            </div>
            <div>
              <Grid>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="External client"
                    label={<FormattedMessage id="name" />}
                    withAsterisk
                    value={values?.name}
                    onChange={(t) => handleChange("name")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="https://api.com/send-here"
                    withAsterisk
                    label={<FormattedMessage id="link" />}
                    value={values?.url}
                    disabled={values?.status == "local"}
                    onChange={(t) => handleChange("url")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="Bearer 1234-key"
                    withAsterisk
                    label={<FormattedMessage id="key" />}
                    value={values?.key}
                    disabled={values?.status == "local"}
                    onChange={(t) => handleChange("key")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <ColorInput
                    withAsterisk
                    label={<FormattedMessage id="color" />}
                    value={values?.color}
                    onChange={(c) => handleChange("color")(c)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="1"
                    label={<FormattedMessage id="index" />}
                    withAsterisk
                    value={values?.index}
                    onChange={(t) => handleChange("index")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <Select
                    label={<FormattedMessage id="status" />}
                    placeholder="Pick one"
                    value={values?.status}
                    onChange={(t) => handleChange("status")(t)}
                    disabled={values?.status == "local"}
                    data={[
                      {
                        value: "active",
                        label: intl.formatMessage({ id: "active" }),
                      },
                      {
                        value: "local",
                        disabled: true,
                        label: intl.formatMessage({ id: "local" }),
                      },
                      {
                        value: "disabled",
                        label: intl.formatMessage({ id: "disabled" }),
                      },
                    ]}
                  />
                </Grid.Col>
                <Grid.Col xs={12} />
                <Grid.Col xs={2}>
                  <Button
                    onClick={() => saveLeadClient()}
                    fullWidth
                    style={{
                      backgroundColor: "dodgerblue",
                      marginTop: 10,
                    }}
                  >
                    <FormattedMessage id="save" />
                  </Button>
                </Grid.Col>
                <Grid.Col xs={2}>
                  <Button
                    onClick={() => verifyApiData()}
                    fullWidth
                    style={{
                      backgroundColor: "dodgerblue",
                      marginTop: 10,
                    }}
                  >
                    <FormattedMessage id="verify_connection" />
                  </Button>
                </Grid.Col>
              </Grid>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
