import { useState, useEffect } from "react";
import { Box, Heading, Flex } from "@chakra-ui/react";
import { Table, Alert } from "@mantine/core";
import { AlertCircle } from "tabler-icons-react";
import { getClients } from "../../functions/api";
import { Input } from "@mantine/core";
import { FormattedMessage, useIntl } from 'react-intl';

const AdminClientScreen = () => {
  const intl = useIntl();
  const [clients, setClients] = useState([]);
  const [sortedClients, setSortedClients] = useState([]);
  const [sortKey, setSortKey] = useState("c_created");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getClients(null);
        setClients(data);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const sorted = [...clients].sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (a[sortKey] > b[sortKey]) {
        return sortDirection === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });
    setSortedClients(sorted);
  }, [clients, sortKey, sortDirection]);

  const handleSort = (key) => {
    if (key === sortKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortDirection("asc");
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value !== null) {
      setSearchValue(value.toLowerCase());
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleRowClick = (id) => {
    const url = `/partner/${id}`;
    window.open(url, "_blank");
  };
  

  return (
    <Box maxW="full" px={10} py={6}>
      <Heading mb={4}>Client List</Heading>
      <Alert icon={<AlertCircle size="2rem" />} title={intl.formatMessage({id: "soon"})} color="green" style={{marginBottom: 15}}>
      <FormattedMessage id="option_to_sort_client" />
    </Alert>
      <Input
        placeholder={ intl.formatMessage({id: "search"})}
        mb={4}
        onChange={handleSearch}
        size="lg"
        radius="lg"
        variant="default"
        style={{ boxShadow: "none", borderColor: "gray.300" }}
      />
      <Box overflowX="auto">
        <Table striped>
          <thead>
            <tr>
              <th onClick={() => handleSort("c_name")}><FormattedMessage id="name_surname" /></th>
              <th onClick={() => handleSort("c_email")}><FormattedMessage id="email" /></th>
              <th onClick={() => handleSort("c_phone")}><FormattedMessage id="phone" /></th>
              <th onClick={() => handleSort("c_main_responsible")}>
              <FormattedMessage id="responsible" />
              </th>
              <th onClick={() => handleSort("c_type")}><FormattedMessage id="client_type" /></th>
              <th onClick={() => handleSort("c_created")}><FormattedMessage id="created_at" /></th>
            </tr>
          </thead>
          <tbody>
            {searchValue.length > 3 && sortedClients
              .filter(
                (client) =>
                  client.c_name.toLowerCase().includes(searchValue) ||
                  client.c_email.toLowerCase().includes(searchValue) ||
                  client.c_phone.toLowerCase().includes(searchValue)
              )
              .map((client) => (
                <tr
                  key={client.clientid}
                  onClick={() => handleRowClick(client.clientid)}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    {client.c_name} {client.c_surname}
                  </td>
                  <td>{client.c_email || "-"}</td>
                  <td>{client.c_phone || "-"}</td>
                  <td>
                    {client.name || client.surname
                      ? (client.name || "-") + " " + (client.surname || "-")
                      : "-"}
                  </td>
                  <td>{client.c_type || "-"}</td>
                  <td>{formatDate(client.c_created)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Box>
      {searchValue.length <= 3 && (
        <Flex justify="center" mt={6}>
          <FormattedMessage id="enter_four_search" />
        </Flex>
      )}
      {sortedClients.length === 0 && (
        <Flex justify="center" mt={6}>
          <FormattedMessage id="no_clients_found" />
        </Flex>
      )}
    </Box>
  );
};

export default AdminClientScreen;
