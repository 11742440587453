import {
  Button,
  Card,
  Grid,
  MultiSelect,
  Select,
  Title,
  Switch,
} from "@mantine/core";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const AdminAutomation = () => {
  const { key } = useParams();
  const [automation, setAutomation] = useState();

  if (!key) {
    return (
      <Card withBorder mt={20}>
        <a
          href="automation/new"
          style={{
            backgroundColor: "dodgerblue",
            padding: 8,
            color: "white",
            borderRadius: 3,
          }}
        >
          New Automation
        </a>
      </Card>
    );
  }

  if (key != "new") {
  }

  const existingClientActions = [
    {
      action: "New Task",
      data: [
        { title: "assign to", input: "users" },
        { title: "Task title", input: "text" },
      ],
    },
  ];

  const autoRoutes = {
    email: {
      triggers: [
        {
          trigger: "New Email",
          conditions: [
            { condition: "New Client", actions: existingClientActions },
            { condition: "Existing Client", actions: existingClientActions },
          ],
        },
      ],
    },
    client: {
      triggers: [{ trigger: "New Client" }, { trigger: "Old Client" }],
    },
    api: {
      triggers: [{ trigger: "New Client" }],
    },
    invoice: {
      triggers: [
        { trigger: "Paid Invoice" },
        { trigger: "Delayed Invoice" },
        { trigger: "Voided Invoice" },
      ],
    },
  };

  return (
    <Card withBorder mt={20}>
      <Title order={3}>{key != "new" ? "" : "New Automation"}</Title>
      <Grid style={{ paddingTop: 20 }}>
        <Grid.Col
          xs={3}
          style={{
            borderRight: "0.5px solid black",
            marginTop: 10,
            paddingBottom: 40,
          }}
        >
          <Select
            style={{ paddingTop: 10 }}
            label="(IF) Trigger event"
            placeholder="Trigger event"
            data={[
              { value: "new_email", label: "New Email" },
              { value: "existing_client", label: "Existing client" },
              { value: "new_x_email", label: "New Email" },
            ]}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <Switch size="xl" onLabel="AND" offLabel="OR" />
          </div>
          <Select
            style={{ paddingTop: 10 }}
            label="(IF) Trigger event"
            placeholder="Trigger event"
            data={[
              { value: "new_email", label: "New Email" },
              { value: "existing_client", label: "Existing client" },
              { value: "new_x_email", label: "New Email" },
            ]}
          />
        </Grid.Col>
      </Grid>
    </Card>
  );
};
