import { Badge, MultiSelect } from "@mantine/core";
import { useEffect, useState } from "react";
import { labelStyle } from "../../functions/styleFunctions";
import { useIntl } from 'react-intl';

const EditableMulti = ({ edit, srcs, onInputChange, selectedSources }) => {
  const intl = useIntl();
  const [sources, setSources] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (srcs) setSources(sterilizeSources(srcs));
    if (selectedSources && selectedSources[0] != null) {
      const sterilizedSelectedSources = sterilizeSources(selectedSources);
      setSelectedItems(sterilizedSelectedSources);
      setSelectedValues(
        sterilizedSelectedSources.map((s) => s.value.toString())
      );
    }
  }, [srcs, selectedSources]);

  const handleSourceChange = (values) => {
    const newSelectedItems = srcs.filter((v) => values.includes(v.source_id));
    onInputChange(newSelectedItems);
  };

  const sterilizeSources = (data) => {
    return data.map((source) => ({
      color: source.source_color,
      textc: source.source_text_color,
      label: source.source_name,
      value: source.source_id,
      linkid: source.linkid ? source.linkid : 0,
    }));
  };

  return (
    <>
      {!edit &&
        selectedItems &&
        selectedItems.map(
          (cs) =>
          cs && (<SourceBadge cs={cs} />)
        )}
      {edit && sources && (
        <MultiSelect
          style={{ marginTop: 20 }}
          data={sources}
          label={intl.formatMessage({id: "client_sources"})}
          placeholder={intl.formatMessage({id: "select_sources"})}
          searchable
          clearable
          multiple
          value={selectedValues && selectedValues}
          onChange={(values) => handleSourceChange(values)}
        />
      )}
    </>
  );
};

export const SourceBadge = ({cs}) => {
  return (
    <Badge size="lg" style={labelStyle(cs.color ? cs.color : cs.source_color, cs.textc ? cs.textc : cs.source_text_color)} key={cs.linkid} >{cs.label ? cs.label : cs.source_name} </Badge>
  )
}

export default EditableMulti;
