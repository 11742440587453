import {
  Card,
  Text,
  Badge,
  Button,
  Grid,
  Modal,
  Textarea,
  Dialog,
  Group,
  Overlay,
} from "@mantine/core";
import { format } from "date-fns";
import {
  CreditCard,
  BuildingBank,
  ExternalLink,
  User,
} from "tabler-icons-react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  SubmitComment,
  getInvoice,
  triggerInvoicePaid,
  triggerInvoiceSend,
  updatePaidPadte,
} from "../../functions/api";
import { ModifyInvoiceModal } from "./InvoicesPage";
import { PinButton, CallButton, Comment } from "../../Components";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { PDFdoc, returnPDFdoc } from "./PDFscreen";
import { getGroup } from "../../functions/tokens";
import hasAccess from "../../functions/access";
import { useDisclosure } from "@mantine/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { DateInput } from "@mantine/dates";

const InvoicePage = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [invoice, setInvoice] = useState();
  const [open, setOpen] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [modified, setModified] = useState(false);
  const [modPaidDate, setModPaidDate] = useState(null);

  const [comment, setComment] = useState();

  useEffect(() => {
    getInvoice({ invoice_id: id }).then((data) => {
      setInvoice(data);
    });
  }, [open]);

  if (!invoice) {
    return (
      <div>
        <FormattedMessage id="loading" />
      </div>
    );
  }

  const {
    created_on,
    due_date,
    total_novat,
    total_vat,
    services_json,
    status,
    credentials,
    client_json,
    user,
    vat_perc,
    vat_value,
    client,
    extended_due,
    paid_amount,
    discount,
  } = invoice;

  const createdDate = format(new Date(created_on), "dd.MM.yyyy");
  const dueDate = format(new Date(due_date), "dd.MM.yyyy");
  const extendedDate = extended_due
    ? format(new Date(extended_due), "dd.MM.yyyy")
    : null;
  const bankName = credentials.bank_name;
  const bankAccount = credentials.bank_account;

  const services = JSON.parse(services_json);
  // const serviceNames = services.map((service) => service.name).join(", ");
  const serviceCosts = services.map((service) => service.cost);
  const subtotal = serviceCosts.reduce((a, b) => Number(a) + Number(b), 0);

  let group = getGroup();
  group = group == null ? 10 : group;

  const modifyPaidDate = () => {
    const updatedDate = new Date(modPaidDate);
    updatedDate.setHours(updatedDate.getHours() + 10);

    updatePaidPadte({ date: updatedDate, invoice_id: invoice.invoice_id });
    setModified(false);
    window.location.reload();
  };

  const submitComment = () => {
    SubmitComment({
      comment: comment,
      clientid: client.clientid,
      invoiceid: invoice.invoice_id,
    });
    close();
    setComment("");
  };

  const renderPaymentMethodIcon = (method) => {
    switch (method) {
      case "Credit Card":
        return <CreditCard size={24} color="gray" />;
      case "Bank Transfer":
        return <BuildingBank size={24} color="gray" />;
      default:
        return null;
    }
  };

  const callSend = async () => {
    if (window.confirm(intl.formatMessage({ id: "confirm_send" }))) {
      getInvoice({ invoice_id: id }).then(async (dx) => {
        try {
          const pdfBlob = await pdf(returnPDFdoc(dx)).toBlob();
          const ret = await triggerInvoiceSend(pdfBlob, id);

          if ((await ret.message) == "WIN") {
            window.location.reload();
          }
        } catch (e) {
          // console.log(e);
        }
      });
    }
  };

  const callPaid = () => {
    if (hasAccess(group, "markInvoices", "edit")) {
      if (
        window.confirm(
          intl.formatMessage({ id: "confirm_send" }) + total_vat + "?"
        )
      ) {
        triggerInvoicePaid(id).then(() => window.location.reload());
      }
    }
  };
  const paidDate = new Date(invoice.paid_date);
  paidDate.setHours(paidDate.getHours() + 10);
  return (
    <>
      <Dialog
        position={{ top: 90, right: 20 }}
        opened={modified}
        withCloseButton
        onClose={() => setModified(false)}
        size="md"
        radius="md"
      >
        <Text size="sm" mb="xs" weight={500}>
          <FormattedMessage id="modify_invoice_paid_data" />
        </Text>

        <Group align="flex-end">
          <DateInput
            valueFormat="DD-MM-YYYY"
            outputFormat="YYYY-MM-DD"
            value={modPaidDate}
            onChange={setModPaidDate}
          />
          <Button
            style={{ backgroundColor: "dodgerblue" }}
            onClick={modifyPaidDate}
          >
            <FormattedMessage id="save" />
          </Button>
        </Group>
      </Dialog>
      {modified && (
        <Overlay
          color="#000"
          opacity={0.4}
          style={{ marginTop: 64, height: 980 }}
        />
      )}
      <Card shadow="sm" radius="lg">
        <ModifyInvoiceModal
          isModalOpen={open}
          callBack={setOpen}
          selectedInvoice={invoice}
        />
        <Modal
          opened={opened}
          onClose={close}
          title={intl.formatMessage({ id: "add_comment" })}
          yOffset="30vh"
        >
          <Textarea
            value={comment}
            onChange={(text) => setComment(text.target.value)}
          />
          <Button
            style={{ marginTop: 20 }}
            onClick={submitComment}
            variant="outline"
          >
            <FormattedMessage id="add_comment" />p
          </Button>
        </Modal>
        <div className="flex items-center justify-between mb-4">
          <PinButton itemType={"invoice"} itemId={invoice.invoice_id} />
          <div style={{ display: "flex", alignContent: "flex-end" }}>
            {invoice.status &&
              invoice.status == "draft" &&
              (invoice.client.c_email ? (
                <Button
                  style={{
                    backgroundColor: "dodgerblue",
                    display: "inline-block",
                    marginRight: 30,
                  }}
                  onClick={() => callSend(invoice)}
                  mr={3}
                >
                  <FormattedMessage id="send_to_client" />
                </Button>
              ) : (
                <p style={{ fontWeight: "bold" }}>
                  <FormattedMessage id="request_email_for_send" />
                </p>
              ))}

            {invoice.status &&
              invoice.status == "sent" &&
              hasAccess(group, "markInvoices", "edit") && (
                <Button
                  style={{
                    backgroundColor: "dodgerblue",
                    display: "inline-block",
                    marginRight: 30,
                  }}
                  onClick={() => callPaid(invoice)}
                  mr={3}
                >
                  <FormattedMessage id="mark_paid" />
                </Button>
              )}
            {invoice.status == "paid" && group < 10 && (
              <Button
                onClick={() => setModified(!modified)}
                style={{
                  backgroundColor: "dodgerblue",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <FormattedMessage id="modify_invoice_paid_data" />
              </Button>
            )}
            {invoice.status == "void" ? (
              <Badge color={"red"} variant="filled">
                <FormattedMessage id="annuled" />
              </Badge>
            ) : (
              <Button
                style={{ backgroundColor: "dodgerblue" }}
                onClick={() => setOpen(true)}
              >
                <FormattedMessage id="edit" />
              </Button>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div>
            <Link
              to={{
                pathname: `/partner/${client.clientid}`,
                query: { foo: "bar" },
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "dodgerblue" }}
            >
              <ExternalLink
                size={16}
                style={{
                  display: "inline-block",
                  paddingBottom: 1,
                }}
              />
              <Text
                style={{
                  display: "inline-block",
                  fontSize: 14,
                  textDecorationLine: "underline",
                }}
              >
                {" "}
                {intl.formatMessage({ id: "view_client_new_tab" })}
              </Text>
            </Link>
            <div className="flex items-center">
              <User style={{ marginRight: 15, marginTop: 15 }} size={75} />
              <Text>
                {client.c_name} {client.c_surname}
              </Text>
            </div>
            {/* Klienta tel.nr: {client.c_phone} */}
          </div>
          <div style={{ textAlign: "right" }}>
            <FormattedMessage id="jbmnj_nr" />
            {invoice.invoice_name}
            <Text style={{ fontSize: 14, color: "gray" }}>{createdDate}</Text>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center"></div>
          {extendedDate ? (
            <span style={{ display: "flex" }}>
              <Text style={{ marginRight: 10, textDecoration: "line-through" }}>
                {dueDate}
              </Text>
              <Text style={{}}>{extendedDate}</Text>
            </span>
          ) : (
            <Text>{dueDate}</Text>
          )}
        </div>
        <div className="mb-4">
          {invoice.status == "paid" && (
            <Text weight={500} className="mb-2">
              {/* Paid at {invoice.paid_date} */}
              {intl.formatMessage({ id: "paid_at" })}
              {paidDate.toISOString().slice(0, 10)}
            </Text>
          )}
          <Text weight={500} className="mb-2">
            {intl.formatMessage({ id: "services" })}
            {/* {serviceNames} */}
          </Text>
          {serviceCosts.map((cost, index) => (
            <div key={index} className="flex justify-between">
              <Text>{services[index].name}</Text>
              <Text>{cost} EUR</Text>
            </div>
          ))}
        </div>
        <div className="flex justify-between mb-4">
          <Text>{intl.formatMessage({ id: "subtotal" })}</Text>
          <Text>{subtotal.toFixed(2)} EUR</Text>
        </div>
        <div className="flex justify-between mb-4">
          <Text>
            {intl.formatMessage({ id: "vat" })} ({vat_perc}):
          </Text>
          <Text>{vat_value} EUR</Text>
        </div>
        {discount && (
          <div className="flex justify-between mb-4">
            <Text weight={700}>Discount</Text>
            <Text weight={500}>{discount} %</Text>
          </div>
        )}
        <div className="flex justify-between mb-4">
          <Text weight={700}>{intl.formatMessage({ id: "total" })}</Text>
          <Text weight={500}>{total_vat} EUR</Text>
        </div>

        {discount && (
          <div className="flex justify-between mb-4">
            <Text weight={700}>
              {intl.formatMessage({ id: "total_with_discount" })}
            </Text>
            <Text weight={500}>{total_vat * (1 - discount / 100)} EUR</Text>
          </div>
        )}
        {paid_amount && (
          <div className="flex justify-between mb-1">
            <Text>{intl.formatMessage({ id: "already_paid" })}</Text>
            <Text>{paid_amount} EUR</Text>
          </div>
        )}
        {paid_amount && (
          <div className="flex justify-between mb-4">
            <Text>{intl.formatMessage({ id: "left" })}:</Text>
            <Text>
              {discount
                ? total_vat * (1 - discount / 100) - paid_amount
                : total_vat - paid_amount}{" "}
              EUR
            </Text>
          </div>
        )}
        <hr />
        {client && client.c_phone && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div>
                  <FormattedMessage id="client_phone_nr" />
                  {client.c_phone}
                </div>
                <div style={{ fontSize: 12 }}>{client.c_bussinessid}</div>
              </div>

              <div>
                <CallButton client={client} />
              </div>
            </div>
            <hr />
          </>
        )}
        <Grid>
          <Grid.Col xs={6}>
            <div className="flex items-center justify-between pt-5">
              <div className="flex items-center">
                {renderPaymentMethodIcon("Bank Transfer")}
                <div style={{ marginLeft: 10, fontSize: 12 }}>
                  <Text>{credentials.company_name}</Text>
                  <Text>
                    {intl.formatMessage({ id: "reg" })}
                    {credentials.reg_nr}{" "}
                  </Text>
                  {credentials.vat && (
                    <Text>
                      {intl.formatMessage({ id: "vat" })}
                      {credentials.vat}
                    </Text>
                  )}
                  <Text>
                    {bankName} ({bankAccount})
                  </Text>
                </div>
              </div>
              <Text>{user}</Text>
            </div>
            <PDFDownloadLink
              document={<PDFdoc id={id} />}
              style={{
                cursor: "pointer",
                display: "inline",
              }}
              fileName={"INV" + invoice.invoice_name + "invoice.pdf"}
            >
              {({ blob, url, loading, error }) =>
                !loading && (
                  <Text
                    style={{
                      padding: 10,
                      backgroundColor: "dodgerblue",
                      color: "white",
                      borderRadius: 10,
                      marginTop: 10,
                      textAlign: "center",
                    }}
                  >
                    {intl.formatMessage({ id: "download_invoice" })}
                  </Text>
                )
              }
            </PDFDownloadLink>
            {invoice.paid_amount && (
              <PDFDownloadLink
                document={<PDFdoc id={id} modified={true} />}
                style={{
                  cursor: "pointer",
                  display: "inline",
                }}
                fileName={"INV" + invoice.invoice_name + "invoice.pdf"}
              >
                {({ blob, url, loading, error }) =>
                  !loading && (
                    <Text
                      style={{
                        padding: 10,
                        backgroundColor: "dodgerblue",
                        color: "white",
                        borderRadius: 10,
                        marginTop: 10,
                        textAlign: "center",
                      }}
                    >
                      {intl.formatMessage({ id: "download_modified_invoice" })}
                    </Text>
                  )
                }
              </PDFDownloadLink>
            )}
          </Grid.Col>
          <Grid.Col xs={6} style={{ padding: 20 }}>
            <Button
              variant="outline"
              color="grape"
              radius={0}
              onClick={toggle}
              style={{ marginBottom: 15 }}
            >
              <FormattedMessage id="add_comment_invoice" />
            </Button>
            {invoice.comments &&
              invoice.comments[0].created != null &&
              invoice.comments.map((cm) => (
                <Comment
                  key={cm.comment_id}
                  postedAt={cm.created}
                  body={cm.comment}
                  author={
                    cm?.name + " " + (cm?.surname != null ? cm.surname : "")
                  }
                />
              ))}
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default InvoicePage;
