import { Fragment, useEffect, useState } from "react";
import { getReportFilters } from "../../functions/api";
import {
  Box,
  Button,
  Card,
  Grid,
  Loader,
  MultiSelect,
  Tabs,
} from "@mantine/core";
import { Route, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePickerInput } from "@mantine/dates";
import { stateObject } from "./AdminReports";

export const MarketingScreenRoutes = () => {
  return (
    <>
      <Route exct path="/marketing/:tab" element={<MarketingScreen />} />
      {MarketingTabs.map((route) => (
        <Fragment key={route.value}>
          {route.disabled !== true && (
            <Route
              exact
              path={`/marketing/${route.value.replace(/_/g, "-")}/:key`}
              element={route.route ? route.route : route.component}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

export const MarketingScreen = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  return (
    <Box bg="gray.100" minH="100vh" p={8}>
      <Tabs
        keepMounted={false}
        color="teal"
        value={tab}
        onTabChange={(value) => navigate(`/marketing/${value}`)}
      >
        <Tabs.List>
          {MarketingTabs.map((tab) => (
            <Tabs.Tab
              value={tab.value.replace(/_/g, "-")}
              color={tab.color}
              disabled={tab.disabled}
            >
              <FormattedMessage id={tab.value} />
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {MarketingTabs.map((tab) => (
          <Tabs.Panel value={tab.value.replace(/_/g, "-")}>
            {tab.disabled !== true && tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Box>
  );
};

export const MarketingCampaignScreen = () => {
  const { key } = useParams();
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);

  const [filters, setFilters] = useState(null);

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  if (key == "new") {
    const handleChange = (name) => (value) => {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    };

    const submitFilters = () => {
      console.log(values);
      //getReportClientResults(values).then((x) => setResults(x));
    };

    return (
      <>
        {!filters ? (
          <Loader />
        ) : (
          <Card withBorder mt={10}>
            <Grid>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={[
                    {
                      value: "closed",
                      label: intl.formatMessage({ id: "closed" }),
                    },
                    {
                      value: "active",
                      label: intl.formatMessage({ id: "active" }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "client_status" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.clientStatus}
                  onChange={handleChange("clientStatus")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters ? filters?.users : []}
                  label={intl.formatMessage({ id: "client_creator" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.whoCreated}
                  onChange={handleChange("whoCreated")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters?.sources}
                  label={intl.formatMessage({ id: "client_source" })}
                  placeholder={intl.formatMessage({ id: "select_source" })}
                  value={values.source}
                  onChange={handleChange("source")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters?.closes}
                  label={intl.formatMessage({ id: "closing_reason" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.closedReason1}
                  onChange={handleChange("closedReason1")}
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <DatePickerInput
                  allowSingleDateInRange
                  clearable
                  type="range"
                  label={intl.formatMessage({ id: "creation_range" })}
                  placeholder={intl.formatMessage({ id: "pick_date_range" })}
                  value={values.creationRange}
                  onChange={handleChange("creationRange")}
                  mx="auto"
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <DatePickerInput
                  allowSingleDateInRange
                  clearable
                  type="range"
                  label={intl.formatMessage({ id: "closure_range" })}
                  placeholder={intl.formatMessage({ id: "pick_date_range" })}
                  value={values.closeRange}
                  onChange={handleChange("closeRange")}
                  mx="auto"
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <MultiSelect
                  searchable
                  data={[
                    { value: "closed", label: "Closed" },
                    { value: "created", label: "Created" },
                  ]}
                  label="Group by"
                  placeholder="Select grouping"
                  value={values.showBy}
                  onChange={handleChange("showBy")}
                />
              </Grid.Col>
              <Grid.Col xs={10}>
                <Button
                  style={{ backgroundColor: "dodgerblue" }}
                  onClick={() => submitFilters()}
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Grid.Col>
            </Grid>
          </Card>
        )}
      </>
    );
  }

  return (
    <Card withBorder mt={10}>
      <a
        style={{
          backgroundColor: "dodgerblue",
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          color: "white",
          borderRadius: 5,
          cursor: "pointer",
        }}
        href="campaigns/new"
      >
        New
      </a>
    </Card>
  );
};

export const MarketingTemplateScreen = () => {
  const { key } = useParams();
  return <></>;
};

export const MarketingItem = () => {
  const [filters, setFilters] = useState();
  //last task ->
  // closed ->
  useEffect(() => {
    getReportFilters().then((x) => {
      setFilters(x);
    });
  }, []);
  return <></>;
};

export const MarketingTabs = [
  {
    value: "campaigns",
    color: "green",
    disabled: false,
    component: <MarketingCampaignScreen />,
  },
  {
    value: "email_templates",
    color: "red",
    disabled: false,
    component: <MarketingTemplateScreen />,
  },
];
